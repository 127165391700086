/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Form,Badge } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import CommonLogout from "./CommonLogout";
import EmptySpaceFieldValid from "./EmptySpaceFieldValid";
import CheckValid from "./CheckValid";
import CommonToggle from "../Common/CommonToggle";
import {useParams} from 'react-router-dom';

const CommonewModel = (props) => {
    const Dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const { websocket } = useContext(WebSocketContext);
    const { showModalNew, setShowModalNew, ConfirmOrder} = props;
    const { accessToken, device_id, boxList} = useSelector((state) => state.adminReducers);
    const [ CreateBoxState, setCreateBoxState ] = useState({
        box_name : "", 
        box_height : "", 
        box_width : "", 
        box_length : "", 
        is_active : true
    })
    const exceptThisCostSymbols = ["e", "E", "+", "-"];
    const boxData = boxList?.list?.filter((elm) => elm?.id === showModalNew?.Data?.id)[0];

    // error state
    const [ errorbox_name, setErrorbox_name ] = useState("");
    const [ errorbox_height, setErrorbox_height ] = useState("");
    const [ errorbox_width, setErrorbox_width ] = useState("");
    const [ errorbox_length, setErrorbox_length ] = useState("");
    const { roomId } = useParams();
    // close modal
    const handleClose = () => {
        setShowModalNew({...showModalNew, open: false});
    };
    
    useEffect(() => {
        if(showModalNew?.subtitle === "Update New Box") {
            setCreateBoxState({
                ...CreateBoxState,
                box_name:boxData?.box_name,
                box_height:boxData?.box_height,
                box_width:boxData?.box_width,
                box_length:boxData?.box_length,
                is_active: boxData?.is_active,
            });
        };
    }, [ boxData ])
    const CommonFunction = async () => {
        if(showModalNew?.subtitle === "Active Product Application") {
            let param = { 
                "transmit": "broadcast", 
                "url": "brand_edit",
                "request":{
                    "brand_image": showModalNew?.Data?.brand_image,
                    "brand_name": showModalNew?.Data?.brand_name,
                    "brand_id" : showModalNew?.Data?.id,
                    "is_active" : showModalNew?.Data?.is_active ? false : true,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
            handleClose();
        } else if(showModalNew?.subtitle === "Active Category") {
            let param = { 
                "transmit": "broadcast", 
                "url": "category_edit",
                "request" : { 
                    "category_id" : showModalNew?.Data?.id,
                    "category_image" : showModalNew?.Data?.category_image, 
                    "brand_id" : showModalNew?.Data?.brand_id,
                    "category_name" : showModalNew?.Data?.category_name,
                    "is_active" : showModalNew?.Data?.is_active ? false : true,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
            handleClose();
        } else if(showModalNew?.subtitle === "Order-Purchase") {
            setLoader(true);
            await ConfirmOrder();
            setTimeout(()=>setLoader(false), [500])
        } else if(showModalNew?.subtitle === "Create New Box") {
            if(CreateBoxState?.box_name !== "" && CreateBoxState?.box_height !== "" && CreateBoxState?.box_width !== "" &&
            CreateBoxState?.box_length !== "") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "box_add",
                    "request" : { 
                        "box_name" : CreateBoxState?.box_name,
                        "box_height" : CreateBoxState?.box_height, 
                        "box_width" : CreateBoxState?.box_width,
                        "box_length" : CreateBoxState?.box_length,
                        "is_active" : CreateBoxState?.is_active
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
                OnSuccesListCall("box_list");
                handleClose();
            } else {
                CheckValid(CreateBoxState?.box_name, {type: 'box_name', error: setErrorbox_name});
                CheckValid(CreateBoxState?.box_height, {type: 'box_height', error: setErrorbox_height});
                CheckValid(CreateBoxState?.box_width, {type: 'box_width', error: setErrorbox_width});
                CheckValid(CreateBoxState?.box_length, {type: 'box_length', error: setErrorbox_length});
            };
        }else if(showModalNew?.subtitle === "Update New Box") {
            if(CreateBoxState?.box_name !== "" && CreateBoxState?.box_height !== "" && CreateBoxState?.box_width !== "" &&
            CreateBoxState?.box_length !== "") {
                let param = { 
                    "transmit": "broadcast", 
                    "url": "box_edit",
                    "request" : { 
                        "box_id":boxData?.id,
                        "box_name" : CreateBoxState?.box_name,
                        "box_height" : CreateBoxState?.box_height, 
                        "box_width" : CreateBoxState?.box_width,
                        "box_length" : CreateBoxState?.box_length,
                        "is_active" : CreateBoxState?.is_active
                    },
                    "DeviceId" : device_id
                };
                wsSend_request(websocket, param);
                OnSuccesListCall("box_list");
                handleClose();
            } else {
                CheckValid(CreateBoxState?.box_name, {type: 'box_name', error: setErrorbox_name});
                CheckValid(CreateBoxState?.box_height, {type: 'box_height', error: setErrorbox_height});
                CheckValid(CreateBoxState?.box_width, {type: 'box_width', error: setErrorbox_width});
                CheckValid(CreateBoxState?.box_length, {type: 'box_length', error: setErrorbox_length});
            };
        }else if(showModalNew?.subtitle ==="Order Warehouse Approve"){
            let param = { 
                "transmit": "broadcast", 
                "url": "wh_order_approval",
                "request" : { 
                    "order_id" : roomId,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }else if(showModalNew?.subtitle ==="Order Admin Approve"){
            let param = { 
                "transmit": "broadcast", 
                "url": "wh_approval",
                "request" : { 
                    "order_id" : showModalNew?.Data?.order_id,
                    "warehouse_id" : showModalNew?.Data?.warehouse_id,
                    "warehouse_remark" : showModalNew?.Data?.warehouse_remark,
                    "dispatch_id" : showModalNew?.Data?.dispatch_id,
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        }
        else if(showModalNew?.subtitle === "Logout") {
            CommonLogout(accessToken, Dispatch);
        } else if(showModalNew?.subtitle === "Order-Cancel") {
            props?.callBack()
        };
    };

    const OnSuccesListCall = (url) => {
        if(url === "box_list") {
            let param = { 
                "transmit": "broadcast", 
                "url": url,
                "request" : { 
                    "status" : "", 
                    "limit" : 5000,
                    "page_no" : 1, 
                    "search" : ""
                },
                "DeviceId" : device_id
            };
            wsSend_request(websocket, param);
        };
    };

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<Modal className={showModalNew?.title === "Create Supplier" ? "commonModal addSupplierModel" : (showModalNew?.title === "Create New Box" || showModalNew?.title === "Update New Box") ? "commonModal addBoxModalNew" : "commonModal"} show={showModalNew?.open} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title style={{ width: showModalNew?.title === "Are you sure?" ? "100%" : ""}}>{showModalNew?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {showModalNew?.modalType === "form" ? (
               <div className="formloginset">
                    {(showModalNew?.title === "Create New Box" || showModalNew?.title === "Update New Box") && (<>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Box Name<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="text" 
                                    value={CreateBoxState?.box_name} 
                                    onChange={(e) => setCreateBoxState({...CreateBoxState, box_name: e.target.value})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'box_name', error: setErrorbox_name})}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Box Name"
                                />
                                {errorbox_name !== "" && <span className="error_medotory">{errorbox_name}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Box Length (cm)<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={CreateBoxState?.box_length} 
                                    onChange={(e) => setCreateBoxState({...CreateBoxState, box_length: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'box_length', error: setErrorbox_length})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Box Length"
                                />
                                {errorbox_length !== "" && <span className="error_medotory">{errorbox_length}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Box Width (cm)<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={CreateBoxState?.box_width} 
                                    onChange={(e) => setCreateBoxState({...CreateBoxState, box_width: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'box_width', error: setErrorbox_width})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Box Width"
                                />
                                {errorbox_width !== "" && <span className="error_medotory">{errorbox_width}</span>}
                            </Form.Group>
                        </div>
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Box Height (cm)<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={CreateBoxState?.box_height} 
                                    onChange={(e) => setCreateBoxState({...CreateBoxState, box_height: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'box_height', error: setErrorbox_height})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Box Height"
                                />
                                {errorbox_height !== "" && <span className="error_medotory">{errorbox_height}</span>}
                            </Form.Group>
                        </div>
                    </div>
                    <div className="row">
                        {/* <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Box Weight (kg)<span className="mendatory_star">*</span></Form.Label>
                                <Form.Control 
                                    type="number" 
                                    className="inputBoxs"
                                    value={CreateBoxState?.box_weight} 
                                    onChange={(e) => setCreateBoxState({...CreateBoxState, box_weight: e.target.value.replace(/^0/, "")})}
                                    onKeyUp={(e) => CheckValid(e.target.value, {type: 'box_weight', error: setErrorbox_weight})}
                                    onKeyDown={(e) => {
                                        EmptySpaceFieldValid(e)
                                        exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                                    }}
                                    placeholder="Enter Box Weight"
                                />
                                {errorbox_weight !== "" && <span className="error_medotory">{errorbox_weight}</span>}
                            </Form.Group>
                        </div> */}
                        <div className="col-md-6">
                            <Form.Group className="mb-3" >
                                <Form.Label>Status</Form.Label>
                                <div className="ToggleMainWrap">
                                    <CommonToggle 
                                        valueToggle={CreateBoxState?.is_active}
                                        setValueToggle={() => setCreateBoxState({...CreateBoxState, is_active: !CreateBoxState?.is_active})}
                                        name={""}
                                    />
                                    {CreateBoxState?.is_active ? <Badge bg="success">Active</Badge> : <Badge bg="danger">Inactive</Badge>}
                                </div>
                            </Form.Group>
                        </div>
                    </div>
                    </>)}
                </div>
            ) : (<>
                {showModalNew?.modalType === "not form store details" ? (
                    <div className="notformsection">
                        <p>Do you really want to add the <b>product</b> to the product details report?</p>
                    </div>
                ): (
                    <div className="notformsection">
                        {showModalNew?.subtitle === "Logout" && (<i className="bi bi-box-arrow-right logoutIconbtn"></i>)}
                        <p dangerouslySetInnerHTML={{__html: showModalNew?.description}}></p>
                    </div>
                )}
            </>)}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            {/* <Button disabled={loader} variant="primary" className="addbtncmn" onClick={CommonFunction}>
                {showModalNew?.button}
            </Button> */}
        </Modal.Footer>
    </Modal>)
}

export default CommonewModel;