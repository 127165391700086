/* eslint-disable */
import React, { useState, useEffect, useContext } from "react";
import { Button, Form, Spinner, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import EmptySpaceFieldValid from "../Common/EmptySpaceFieldValid";
import CheckValid from "../Common/CheckValid";
import { useDispatch, useSelector } from "react-redux";
import LogoImage from "../../assets/img/logo-white.png";
import Select from 'react-select';
import { StartCommonLoader, SearchPincodeList, clearRedux, GetFrameList, ErrorMessageFromRedux, StartMainCommonLoader, ClearFormSetFutios, } from "../../redux/actions/adminActions";
import GuestLayout from "../Shared/GuestLayout";
import wsSend_request from "../../Api/ws/ws_request";
import { WebSocketContext } from "../../App";
import { parsePhoneNumber, getRegionCodeForCountryCode } from "awesome-phonenumber";
import { useNavigate, useLocation } from "react-router-dom";
import { CONGRATULATIONS_URL, WELCOME_REGESTER_PRODUCT_URL } from "../Shared/constant";
import UploadFileAPI from "../../Api/UploadFileAPI";
import FrameScanner from "../Common/FrameScanner";
import ScanSuccessAudio from '../../assets/audio/scan-success.mp3';
import ScanErrorAudio from '../../assets/audio/scan-error.mp3';
import CommonewModel from "../Common/CommonewModel";

const Registerproduct = (props) => {
    const { setGetCurrentPath } = props;
    const [ImageDisplay, setImageDisplay] = useState("");
    const pathname = useLocation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();
    const [ UploadFileLoader, setUploadFileLoader ] = useState(false);
    const { websocket } = useContext(WebSocketContext);
    const { ErrorMessageFromReddux, commonLoader, cacheClear__, searchPincodeList, ClearFormSet, AllDealerFranchiseTypeListAll, device_id, getFrameList, franchiseTypeListAll, commonMainLoader,productlistall } = useSelector((state) => state.adminReducers);
    const [ApplicationState, setApplicationState] = useState({
        FirstName: "",
        LastName: "",
        Email: "",
        Mobile: "",
        UseCase: "",
        AgeGroup: "",
        PINCode: "",
        ProductValue: "",
    });
    const [ProductDetailsState, setProductDetailsState] = useState({
        frame_auto_scanner: false,
        FrameNumber: "",
        ProductName: "",
        DealerInfo: "",
        franchise_name: "",
        invoiceImage: null,
        invoiceImageName: "",
    });
    const [ isScan, setisScan ] = useState(false);
    const [ FrameScanningModal, setFrameScanningModal ] = useState({
        open: false,
    });
    const optionsUseCase = [
        { key: 1, value: "Health & Fitness", label: "Health & Fitness" },
        { key: 2, value: "Daily Use - Health", label: "Daily Use - Health" },
        { key: 3, value: "Daily Use - Cost Saving", label: "Daily Use - Cost Saving" },
        { key: 4, value: "Kids", label: "Kids" }
    ];
    const optionsAgeGroups = [
        { key: 1, value: "Below 18", label: "Below 18" },
        { key: 2, value: "18-35", label: "18-35" },
        { key: 3, value: "35 & Above", label: "35 & Above" },
    ];
    const [ showModalNew, setShowModalNew ] = useState({
        open: false,
        title: "",
        modalType: "",
        description: "",
        Data: {}
    });
    // error state
    const [ errorFirstName, seterrorFirstName ] = useState("");
    const [ errorMobile, seterrorMobile ] = useState("");
    const [ errorEmail, seterrorEmail ] = useState("");
    const [ errorAgeGroup, seterrorAgeGroup ] = useState("");
    const [ errorPINCode, seterrorPINCode ] = useState("");
    const [ errorFrameNumber, seterrorFrameNumber ] = useState("");
    const [ errorProductName, seterrorProductName ] = useState("");
    const [ errorDealerInfo, seterrorDealerInfo ] = useState("");
    const [ useErrorCase, seterrorUseCase ] = useState("");
    const [ optionsProductList, setOptionProductList ] = useState([]);
    const [ optionsFranchiseList, setOptionFranchiseList ] = useState([]);
    const [ errorProductValue, seterrorProductValue ] = useState("");
    const [ isVerified, setVerified ] = useState(false);
    
    useEffect(() => {
        if(cacheClear__ === undefined) {
            dispatch(clearRedux());
            Navigate(WELCOME_REGESTER_PRODUCT_URL);
        };
    }, [ cacheClear__ ]);

    
    useEffect(() => {
        setOptionProductList(productlistall?.filter((elm) => elm?.is_active === true && elm?.is_bike === true)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.product_name })))
        setOptionFranchiseList(AllDealerFranchiseTypeListAll?.list?.filter((elm) => elm?.is_active === true && elm?.parent_id === null)?.map((item, index) => ({ ...item, key: item.id, value: item?.id, label: item?.customer_name })))
    }, [productlistall, ErrorMessageFromReddux, AllDealerFranchiseTypeListAll])

    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // eslint-disable-line
    const exceptThisCostSymbols = ["e", "E", "+", "-", "."];

    useEffect(() => {
        dispatch(StartCommonLoader(false));
        dispatch(StartMainCommonLoader({
            url: "",
            loader: false
        }));
        ErrorMessageFromReddux.API=""
        setGetCurrentPath(pathname);
    }, [pathname]);

    // SELECT USER CASE
    const SelectUserCase = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                UseCase: value?.value,
            });
            CheckValid(value, { type: "UseCase", error: seterrorUseCase });
        } else {
            setApplicationState({
                ...ApplicationState,
                UseCase: "",
            });
            CheckValid(value === null ? "" : value, { type: "UseCase", error: seterrorUseCase });
        }
    };

    // SELECT FRANCHISE
    const selectFranchise = (value) => {
        if(value !== null) {
            setProductDetailsState({...ProductDetailsState, DealerInfo: value});
            CheckValid(value, {type: 'DealerInfo', error: seterrorDealerInfo});
        } else {
            setProductDetailsState({...ProductDetailsState, DealerInfo: ""});
            CheckValid(value === null ? "" : value, {type: 'DealerInfo', error: seterrorDealerInfo});
        }
    };

    // SELECT PRODUCT VALUE
    const productValueSelect = (value) => {
        if(value !== null) {
            setProductDetailsState({...ProductDetailsState, ProductName: value});
            CheckValid(value, {type: 'ProductName', error: seterrorProductName});
        } else {
            setProductDetailsState({...ProductDetailsState, ProductName: ""});
            CheckValid(value === null ? "" : value, {type: 'ProductName', error: seterrorProductName});
        }
    };
    
    // FRAME LIST
    const frameList = () => {
        if (ProductDetailsState?.FrameNumber.trim() === getFrameList?.frame?.frame_number.trim()) {
            seterrorFrameNumber("")
            setProductDetailsState(prevState => ({
                ...prevState,
                ProductName: getFrameList.product.product_name,
                DealerInfo: getFrameList?.frame?.order_type === "AO" ? getFrameList?.customer?.ecom_type : getFrameList?.customer?.customer_name
            }));
            seterrorProductName(""); 
            seterrorDealerInfo("");
        }
    };

    // REMOVE IMAGE UPLOADED
    const RemoveUploadImage = () => {
        setProductDetailsState({ ...ProductDetailsState, invoiceImage: "" });
        setImageDisplay("");
    };


    useEffect(() => {
        if (getFrameList?.frame?.frame_number) {
            frameList();
        }
    }, [getFrameList]);

    useEffect(() => {
        if(Object?.keys(getFrameList)?.length > 0) {
            setProductDetailsState({
                ...ProductDetailsState,
                FrameNumber: getFrameList?.frame?.frame_number,
                ProductName: getFrameList?.product?.product_name,
                DealerInfo: getFrameList?.frame?.order_type === "AO" ? getFrameList?.customer?.ecom_type : getFrameList?.customer?.customer_name,
            });
            setVerified(true);
            frameList();
        };
    }, []);

    useEffect(() => {
        let param = {
            "transmit": "broadcast",
            "url": "franchise_list",
            "request": {
                "status": "",
                "limit": 5000,
                "page_no": 1,
                "search": "",
            },
            "DeviceId": device_id
        };
        wsSend_request(websocket, param)
    }, [])

    useEffect(()=>{
        let param = { 
            transmit: "broadcast",
            url: "product_list",
            request: {
                "status" : "",
                "limit" : 5000,
                "page_no" : 1,
                "search" : "",
                "order_by" :"-updated_at",
            },
            "DeviceId" : device_id
        }
        wsSend_request(websocket, param);
    },[])

    // SELECT AGE GROUPS
    const SelectAgeGroups = (value) => {
        if (value !== null) {
            setApplicationState({
                ...ApplicationState,
                AgeGroup: value?.value,
            });
            seterrorAgeGroup("");
        } else {
            setApplicationState({
                ...ApplicationState,
                AgeGroup: "",
            });
            seterrorAgeGroup("Please select age groups");
        }
    };

    // on Enter search pincode
    const onEnterPincodeSearch = (e) => {
        const code = e.which || e.keyCode;
        if (code === 13) {
            SearchPincode();
        };
    };

    // search pincode
    const SearchPincode = () => {
        if (ApplicationState?.PINCode?.length === 6) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "single",
                "url": "pin_code_search",
                "request": {
                    "pin_code": ApplicationState?.PINCode,
                },
            };
            wsSend_request(websocket, param);
        } else {
            dispatch(StartCommonLoader(false));
            dispatch(SearchPincodeList({}));
        }
    };

    var customStyles = {
        option: (provided, state) => ({
            ...provided,
            fontSize: '13px', // Adjust the font size as per your requirement
        })
    };

    // pin code error get from redux
    useEffect(() => {
        if (ErrorMessageFromReddux?.API === "pin_code_search" && ErrorMessageFromReddux?.Error !== "") {
            seterrorPINCode(ErrorMessageFromReddux?.Error);
            setProductDetailsState({
                ...ProductDetailsState,
                frame_auto_scanner: false,
            });
        } 
        if (ErrorMessageFromReddux?.API === "get_frame_number" && ErrorMessageFromReddux?.Error !== "") {
            if(
                ErrorMessageFromReddux?.Error === "We couldn't find this Frame number, kindly verify once or contact our regional manager to raise an issue." || ErrorMessageFromReddux?.Error === "The entered details already exist. Please enter valid frame number"
            ) {
                setShowModalNew({
                    ...showModalNew,
                    open: true,
                    title: "Verify Frame",
                    description: ErrorMessageFromReddux?.Error,
                    modalType: "not form",
                    Data: {}
                });
            };
            setProductDetailsState(prevState => ({
                ...prevState,
                ProductName: "",
                DealerInfo: "",
            }));
            setProductDetailsState({
                ...ProductDetailsState,
                frame_auto_scanner: false,
            });
        } if (errorFrameNumber && errorFrameNumber === "Frame number cannot be empty") {
            setProductDetailsState(prevState => ({
                ...prevState,
                ProductName: "",
                DealerInfo: "",
            }));
            setProductDetailsState({
                ...ProductDetailsState,
                frame_auto_scanner: false,
            });
            ErrorMessageFromReddux.API=""
        };
    }, [ErrorMessageFromReddux ]);

    const PinCOdeSearchFunction = (e) => {
        setApplicationState({ ...ApplicationState, PINCode: e.target.value });
        if(e.target.value?.length === 6  ) {
            dispatch(StartCommonLoader(true));
            let param = {
                "transmit": "single",
                "url": "pin_code_search",
                "request": {
                    "pin_code": e.target.value,
                },
            };
            wsSend_request(websocket, param);
            CheckValid(e.target.value.trim(), { type: 'PINCode', error: seterrorPINCode });
        }
        if (e.target.value === "") {
            dispatch(SearchPincodeList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    };

    // frame update function
    const FrameNumberSearchFunction = (e) => {
        setProductDetailsState({ ...ProductDetailsState, FrameNumber: e.target.value });
        if (e.target.value === "") {
            setProductDetailsState({ ...ProductDetailsState, 
                FrameNumber: "",
                ProductName: "",
                DealerInfo: "",
            });
            dispatch(GetFrameList({}));
            dispatch(ErrorMessageFromRedux({
                API: "",
                Error: "",
            }));
        };
    };

    // sign in function
    const PhoneCode = 91;
    const CountryStates = getRegionCodeForCountryCode(PhoneCode);
    const CheckMobileNumber = parsePhoneNumber(ApplicationState?.Mobile?.toString(), { regionCode: CountryStates });

    useEffect(() => {
        if(ProductDetailsState?.frame_auto_scanner) {
            Verify();
        };
    }, [ ProductDetailsState?.frame_auto_scanner ]);

    const Verify = (e) => {
        try {
            if (ProductDetailsState?.FrameNumber?.trim()) {
                dispatch(StartMainCommonLoader({
                    url: "get_frame_number",
                    loader: true
                }));
                let param = {
                    "transmit": "broadcast",
                    "url": "get_frame_number",
                    "request": {
                        "frame_number": ProductDetailsState?.FrameNumber?.trim(),
                        "customer_name": ApplicationState?.FirstName,
                        "mobile": ApplicationState?.Mobile,
                        "email": ApplicationState?.Email,
                        "use_case": ApplicationState?.UseCase,
                        "age_group": ApplicationState?.AgeGroup,
                        "pin_code_id": searchPincodeList?.pin?.id,
                        "district_id": searchPincodeList?.city?.id,
                        "state_id": searchPincodeList?.state?.id,
                        "product_value": ApplicationState?.ProductValue,
                        "device": "customer"
                    },
                };
                wsSend_request(websocket, param);
                // setVerified(true);
            } else {
                dispatch(StartMainCommonLoader({
                    url: "get_frame_number",
                    loader: false
                }));
                CheckValid(ProductDetailsState?.FrameNumber.trim(), { type: "FrameNumber", error: seterrorFrameNumber })
            }
        } catch (error) {
            console.error("Error in Verify:", error);
        }
    };

    const cancel = () => {
        setVerified(false)
        dispatch(ErrorMessageFromRedux({
            API: "",
            Error: "",
        }));
        seterrorFrameNumber(null)
        dispatch(GetFrameList({}));
    };

    const SubmitRegister = () => {
        if (ApplicationState?.FirstName.trim() !== "" && ApplicationState?.Mobile.trim() !== "" && ApplicationState?.Email.trim() !== "" && ApplicationState?.ProductValue.trim() !== "" &&
        ApplicationState?.UseCase !== "" && ApplicationState?.AgeGroup !== "" && ApplicationState?.PINCode.trim() !== "" && ProductDetailsState?.FrameNumber.trim() !== "" && 
            ProductDetailsState?.ProductName !== "" && ProductDetailsState?.DealerInfo !== ""
        ) {
            if (EmailRegex.test(ApplicationState?.Email) === true && CheckMobileNumber?.valid === true && ApplicationState?.PINCode.trim()?.length === 6) {
                dispatch(StartMainCommonLoader({
                    url: "purchase_add",
                    loader: true
                }));
                let param = {
                    "transmit": "broadcast",
                    "url": "purchase_add",
                    "request": {
                        "customer_name": ApplicationState?.FirstName,
                        "mobile": ApplicationState?.Mobile,
                        "email": ApplicationState?.Email,
                        "use_case": ApplicationState?.UseCase,
                        "age_group": ApplicationState?.AgeGroup,
                        "pin_code_id": searchPincodeList?.pin?.id,
                        "district_id": searchPincodeList?.city?.id,
                        "state_id": searchPincodeList?.state?.id,
                        "frame_number": ProductDetailsState?.FrameNumber,
                        "product_id": Object?.keys(getFrameList)?.length > 0 ? getFrameList?.product?.id :ProductDetailsState?.ProductName.value,
                        "product_name": Object?.keys(getFrameList)?.length > 0  ? getFrameList?.product?.product_name : ProductDetailsState?.ProductName.label,
                        "franchise_id": Object?.keys(getFrameList)?.length > 0  ? getFrameList?.customer?.id : ProductDetailsState?.DealerInfo?.value,
                        "franchise_name": Object?.keys(getFrameList)?.length > 0  ? ProductDetailsState?.DealerInfo : ProductDetailsState?.DealerInfo?.label,
                        "invoice_image": ProductDetailsState?.invoiceImage,
                        "invoice_image_name": ImageDisplay?.name,
                        "frame_available": Object?.keys(getFrameList)?.length > 0,
                        "product_value": ApplicationState?.ProductValue,
                        "device": "customer"
                    },
                    "DeviceId": device_id,
                    "Dealers_info": ProductDetailsState?.DealerInfo,
                };
                wsSend_request(websocket, param)
            } else {
                CheckValid(ApplicationState?.Mobile, { type: 'phone', error: seterrorMobile });
                CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
                CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
            };
        } else {
            CheckValid(ProductDetailsState?.FrameNumber.trim(), { type: "FrameNumber", error: seterrorFrameNumber });
            CheckValid(ProductDetailsState?.ProductName, { type: "ProductName", error: seterrorProductName });
            CheckValid(ProductDetailsState?.DealerInfo, { type: "DealerInfo", error: seterrorDealerInfo });
            CheckValid(ApplicationState?.UseCase, { type: "UseCase", error: seterrorUseCase });
            CheckValid(ApplicationState?.FirstName.trim(), { type: 'Fname', error: seterrorFirstName });
            CheckValid(ApplicationState?.Email.trim(), { type: 'email', error: seterrorEmail });
            CheckValid(ApplicationState?.Mobile.trim(), { type: 'phone', error: seterrorMobile });
            CheckValid(ApplicationState?.PINCode.trim(), { type: 'PINCode', error: seterrorPINCode });
            CheckValid(ApplicationState?.AgeGroup.trim(), { type: 'age-group', error: seterrorAgeGroup });
            CheckValid(ApplicationState?.ProductValue.trim(), { type: 'productValue', error: seterrorProductValue })
        }
    };

    const UploadImage = async (e) => {
        setUploadFileLoader(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);
        const fileresponse = await UploadFileAPI(formData);
        if (fileresponse?.status === 200) {
            setProductDetailsState({ ...ProductDetailsState, invoiceImage: fileresponse?.id });
            setImageDisplay({
                name: files.name
            });
            setUploadFileLoader(false);
        } else {
            setUploadFileLoader(false);
        };
    };

    useEffect(() => {
        if(ClearFormSet?.action === 200 && ClearFormSet?.url === "purchase_add") {
            Navigate(CONGRATULATIONS_URL);
            dispatch(GetFrameList({}));
        };
    }, [ ClearFormSet ]);

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });


    const QRCodeModal = () => {
        setFrameScanningModal({
            ...FrameScanningModal,
            open: true,
        });
    };

    return (<GuestLayout>
        <div className="loginWrapper MainregisterproductWrapper">
            <h1 className="logoheader">
                <Image src={LogoImage} alt="logo" />
            </h1>
            <div className="loginMainblog Mainregisterproduct">
                <h4>Personal Details</h4>
                <div className="formloginset">
                    <Form.Group className="mb-3" >
                        <Form.Label>First Name <span className="mendatory_star">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            value={ApplicationState?.FirstName}
                            onChange={(e) => setApplicationState({ ...ApplicationState, FirstName: e.target.value })}
                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'Fname', error: seterrorFirstName })}
                            onKeyDown={EmptySpaceFieldValid}
                            placeholder="Enter First Name"
                        />
                        {errorFirstName !== "" && <span className="error_medotory">{errorFirstName}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Contact Number <span className="mendatory_star">*</span></Form.Label>
                        <Form.Control
                            type="number"
                            className="inputBoxs"
                            value={ApplicationState?.Mobile}
                            onChange={(e) => setApplicationState({ ...ApplicationState, Mobile: e.target.value.replace(/^0/, "") })}
                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'phone', error: seterrorMobile })}
                            onKeyDown={(e) => {
                                EmptySpaceFieldValid(e)
                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                            }}
                            placeholder="Enter Contact Number"
                            min="0"
                        />
                        {errorMobile !== "" && <span className="error_medotory">{errorMobile}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Email ID <span className="mendatory_star">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            value={ApplicationState?.Email}
                            onChange={(e) => setApplicationState({ ...ApplicationState, Email: e.target.value })}
                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'email', error: seterrorEmail })}
                            onKeyDown={EmptySpaceFieldValid}
                            placeholder="Enter Email ID"
                        />
                        {errorEmail !== "" && <span className="error_medotory">{errorEmail}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                            <Select
                                onChange={(e) => SelectUserCase(e)}
                                isClearable
                                options={optionsUseCase}
                                className="godown-select-container"
                                classNamePrefix="godown-select"
                                styles={customStyles}
                                placeholder="Select Use Case*"
                            />
                        </div>
                        {useErrorCase !== "" && <span className="error_medotory">{useErrorCase}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <div className="selectMultiselect selectMultiselectBrand" id="selectMultiselectBrand">
                            <Select
                                onChange={(e) => SelectAgeGroups(e)}
                                isClearable
                                options={optionsAgeGroups}
                                className="godown-select-container"
                                classNamePrefix="godown-select"
                                styles={customStyles}
                                placeholder="Select Age Group*"
                            />
                        </div>
                        {errorAgeGroup !== "" && <span className="error_medotory">{errorAgeGroup}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>
                            PIN Code
                            <span className="mendatory_star">*</span>
                            <OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 100 }}
                                overlay={<Tooltip style={{ position: "fixed" }} id="button-tooltip" {...props}>
                                    Search by either hitting the enter button or clicking on the search icon.
                                </Tooltip>}
                            >
                                <small className="filedinfomsg"><i className="bi bi-info-circle-fill"></i></small>
                            </OverlayTrigger>
                        </Form.Label>
                        <div className="serachBoxWIth">
                            <Form.Control
                                type="text"
                                value={ApplicationState?.PINCode}
                                onChange={(e) => PinCOdeSearchFunction(e)}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'PINCode', error: seterrorPINCode })}
                                onKeyDown={(e) => {
                                    EmptySpaceFieldValid(e);
                                    onEnterPincodeSearch(e)
                                }}
                                disabled={commonLoader}
                                maxLength={6}
                                placeholder="Enter PIN Code"
                            />
                            <Button className="searchbtn" onClick={() => SearchPincode()}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                    <path d="M21 21l-6 -6"></path>
                                </svg>
                            </Button>
                            {ErrorMessageFromReddux?.API === "pin_code_search" && (<>
                                <div className="pincode_error">
                                    {ErrorMessageFromReddux?.Error !== "" ? (
                                        <i className="bi bi-x-circle-fill" onClick={() => setApplicationState({ ...ApplicationState, PINCode: "" })} style={{ color: "red" }}></i>
                                    ) : (<i className="bi bi-check-circle-fill" style={{ color: "#34b739" }}></i>)}
                                </div>
                            </>)}
                            {commonLoader && (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>)}
                        </div>
                        {errorPINCode !== "" && <span className="error_medotory">{errorPINCode}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>City</Form.Label>
                        <div className="defaultvalueField">
                            {ErrorMessageFromReddux?.Error === "" || ErrorMessageFromReddux?.API  ? searchPincodeList?.city?.district_name : ""}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>State</Form.Label>
                        <div className="defaultvalueField">
                            {ErrorMessageFromReddux?.Error === "" || ErrorMessageFromReddux?.API ? searchPincodeList?.state?.state_name : ""}
                        </div>
                    </Form.Group>
                </div>
                {/* <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                    <Button className="registerbtn" onClick={() => NextStep()}>Next</Button>
                </Form.Group> */}
            </div>
            <div className="loginMainblog Mainregisterproduct">
                <h4>Product Details</h4>
                <div className="formloginset">
                    <Form.Group className="mb-3" >
                        <Form.Label>Product Value <span className="mendatory_star">*</span></Form.Label>
                        <Form.Control
                            type="number"
                            className="inputBoxs"
                            value={ApplicationState?.ProductValue}
                            onChange={(e) => setApplicationState({ ...ApplicationState, ProductValue: e.target.value.replace(/^0/, "") })}
                            onKeyUp={(e) => CheckValid(e.target.value, { type: 'productValue', error: seterrorProductValue })}
                            onKeyDown={(e) => {
                                EmptySpaceFieldValid(e)
                                exceptThisCostSymbols.includes(e.key) && e.preventDefault()
                            }}
                            placeholder="Enter Product Value"
                            min="0"
                        />
                        {errorProductValue !== "" && <span className="error_medotory">{errorProductValue}</span>}
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Form.Label>Frame Number <span className="mendatory_star">*</span></Form.Label>
                        <div className="frame_box_wrapper">
                            <div className="framumberWraps">
                                <Form.Control
                                    type="text"
                                    value={ProductDetailsState?.FrameNumber}
                                    onChange={(e) => FrameNumberSearchFunction(e)}
                                    onKeyUp={(e) => CheckValid(e.target.value, { type: 'FrameNumber', error: seterrorFrameNumber })}
                                    onKeyDown={EmptySpaceFieldValid}
                                    placeholder="Enter Frame Number"
                                    disabled={((commonMainLoader?.url === "get_frame_number" && commonMainLoader?.loader) || Object?.keys(getFrameList)?.length > 0)}
                                />
                                {ErrorMessageFromReddux?.API === "get_frame_number" && (<>
                                    <div className="frame-number-icon">
                                        {ErrorMessageFromReddux?.Error !== "" ? (
                                            <i className="bi bi-x-circle-fill" onClick={() => setProductDetailsState({ ...ProductDetailsState, FrameNumber: "" , ProductName: "", DealerInfo: "", frame_auto_scanner: false}, cancel())} style={{ color: "red" }}></i>
                                        ) : (<i className="bi bi-check-circle-fill" style={{ color: "#34b739" }}></i>)}
                                    </div>
                                </>)}
                                {(commonMainLoader?.url === "get_frame_number" && commonMainLoader?.loader) && (<div className="frame-number-loader">
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>)}
                            </div>
                            <div className="frame_btn_box">
                                <Button 
                                    id="qr_scanning_btn" 
                                    className='qr_scanning_btn' 
                                    onClick={() => QRCodeModal()}
                                    disabled={Object?.keys(getFrameList)?.length > 0}
                                >
                                    <i className="bi bi-upc-scan"></i>
                                </Button>
                                <Button className="clearbtn" onClick={() => setProductDetailsState({ ...ProductDetailsState, FrameNumber: "" , ProductName: "", DealerInfo: "", frame_auto_scanner: false}, cancel())}>
                                    Reset
                                </Button>
                            </div>
                        </div>
                        {errorFrameNumber !== "" && <span className="error_medotory">{errorFrameNumber}</span>}
                    </Form.Group>
                    {Object?.keys(getFrameList)?.length > 0 && (<React.Fragment>
                        <Form.Group className="mb-3" >
                            <Form.Label>Product Name <span className="mendatory_star">*</span></Form.Label>
                            {Object?.keys(getFrameList)?.length > 0 ? (<Form.Control
                                type="text"
                                value={ProductDetailsState?.ProductName}
                                onChange={(e) => setProductDetailsState({ ...ProductDetailsState, ProductName: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'ProductName', error: seterrorProductName })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Product Name"
                                disabled={true}
                            />) : (<div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ProductDetailsState?.ProductName}
                                    onChange={(e) => productValueSelect(e)} 
                                    isClearable 
                                    options={optionsProductList}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select product"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                            </div>)}
                            {errorProductName !== "" && <span className="error_medotory">{errorProductName}</span>}
                        </Form.Group>
                        <Form.Group className="mb-3" >
                            <Form.Label>Dealer Info/Market Place <span className="mendatory_star">*</span></Form.Label>
                            {Object?.keys(getFrameList)?.length > 0 ? (<Form.Control
                                type="text"
                                value={ProductDetailsState?.DealerInfo}
                                onChange={(e) => setProductDetailsState({ ...ProductDetailsState, DealerInfo: e.target.value })}
                                onKeyUp={(e) => CheckValid(e.target.value, { type: 'DealerInfo', error: seterrorDealerInfo })}
                                onKeyDown={EmptySpaceFieldValid}
                                placeholder="Enter Dealer Info"
                                disabled={true}
                            />) : (<div className="selectMultiselect" id="selectMultiselectBrand">
                                <Select 
                                    value={ProductDetailsState?.DealerInfo}
                                    onChange={(e) => selectFranchise(e)} 
                                    isClearable 
                                    options={optionsFranchiseList}
                                    className="godown-select-container" 
                                    classNamePrefix="godown-select"
                                    styles={customStyles}
                                    placeholder="Select Franchise"
                                    menuPlacement="auto"
                                    menuPortalTarget={document.body}
                                />
                            </div>)}
                            {errorDealerInfo !== "" && <span className="error_medotory">{errorDealerInfo}</span>}
                        </Form.Group>
                    </React.Fragment>)}
                </div>
                <Form.Group className="mb-3" style={{ textAlign: "center" }} >
                    <Button 
                        className="registerbtn" 
                        onClick={() => Verify()} 
                        disabled={(ApplicationState?.FirstName.trim() === "" || ApplicationState?.Mobile.trim() === "" || ApplicationState?.Email.trim() === "" || ApplicationState?.ProductValue.trim() === "" || ApplicationState?.UseCase === "" || ApplicationState?.AgeGroup === "" || ApplicationState?.PINCode.trim() === "" || ProductDetailsState?.FrameNumber.trim() === "")}
                    >Verify</Button>
                </Form.Group>
                {Object?.keys(getFrameList)?.length > 0 && (<React.Fragment>
                    <div className="col-12">
                        <Form.Label><h4>Upload Invoice</h4></Form.Label>
                        <Form.Group className="mb-3" >
                            {(ImageDisplay !== undefined && ImageDisplay !== "") ? (<>{
                                ImageDisplay?.name?.split(".").pop() === "pdf" ? (<div className="pdffileviewo">
                                    <i className="bi bi-filetype-pdf"></i> {ImageDisplay?.name}
                                    <sup className="closeIcon" onClick={() => RemoveUploadImage()}></sup>
                                </div>) : (
                                    <div className="pdffileviewo">
                                        <span>{ImageDisplay?.name}</span>
                                        <sup className="closeIcon" onClick={() => RemoveUploadImage()}></sup>
                                    </div>)}
                            </>) : (<div className="uploadfilewithupload">
                                <Form.Control type="file" onChange={(e) => UploadImage(e)} accept=".png, .jpg, .pdf" />
                                {UploadFileLoader && (<Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>)}
                            </div>)}
                        </Form.Group>
                    </div>
                    <Form.Group className="mb-3" style={{ textAlign: "center" }}>
                        <Button className="registerbtn" disabled={UploadFileLoader || (commonMainLoader?.url === "purchase_add" && commonMainLoader?.loader) || (ErrorMessageFromReddux?.API === "get_frame_number" && ErrorMessageFromReddux?.Error === "Frame number already registered.")} id="registerbtn" onClick={() => SubmitRegister()}>
                            {(commonMainLoader?.url === "purchase_add" && commonMainLoader?.loader) && (<Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>)}
                            {UploadFileLoader ? "Please wait file is uploading..." : (ErrorMessageFromReddux?.API === "get_frame_number" && ErrorMessageFromReddux?.Error === "Frame number already registered.") ? "Please enter the valid frame number" : "Submit"}
                        </Button>
                    </Form.Group>
                </React.Fragment>)}
            </div>
        </div>

        {FrameScanningModal?.open && (<FrameScanner 
            FrameScanningModal={FrameScanningModal} 
            setFrameScanningModal={setFrameScanningModal} 
            setisScan={setisScan}
            ProductDetailsState={ProductDetailsState}
            setProductDetailsState={setProductDetailsState}
        />)}

        {(ErrorMessageFromReddux?.Error !== "" && ProductDetailsState?.frame_auto_scanner) && (
            <audio
                src={ScanErrorAudio}
                autoPlay
            />
        )}

        {(ErrorMessageFromReddux?.Error === "" && ProductDetailsState?.frame_auto_scanner) && (
            <audio
                src={ScanSuccessAudio}
                autoPlay
            />
        )}

        {showModalNew?.open && (<CommonewModel 
            showModalNew={showModalNew}
            setShowModalNew={setShowModalNew} 
        />)}
    </GuestLayout>)
}

export default Registerproduct;